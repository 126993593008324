@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&family=Inter:wght@400;500;700&family=Montserrat:wght@700&family=Roboto:wght@400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: system-ui;
  color-scheme: dark;
}

html,
body,
#root,
.app {
  min-height: 100%;
  background-image: url("./assets/background.jpg");
  background-position: center;
  background-size: auto;
}

#home {
  height: 90dvh;
}
